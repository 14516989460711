import clsx from 'clsx';
import React, { JSX, PropsWithChildren, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { PluggableList } from 'react-markdown/lib';
import { Link } from 'react-router-dom';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';

import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';
import { getSourceTypeAndParams } from '../../source/utils/get-source-type-and-params';
import { useGlobalAnalyticsContext } from '../contexts/GlobalAnalyticsContext';

export type InlineCitationRenderProps = React.HTMLProps<HTMLAnchorElement> & { index: number };
type InlineCitationRender = React.ElementType<InlineCitationRenderProps>;
type Props = {
  answer: string;
  renderInlineCitation?: InlineCitationRender;
};

const linkTags = ['a'];
const textTags = ['text', 'p', 'strong', 'em', 'br', 'span'];
const tableTags = ['table', 'thead', 'tbody', 'tr', 'td', 'th', 'caption', 'colgroup', 'col', 'tfoot'];
const listTags = ['ul', 'ol', 'li'];
const headingTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const allowedElements = linkTags.concat(textTags, tableTags, listTags, linkTags, headingTags);

const preprocessLatex = (answer: string) => {
  return answer
    // KaTeX only recognizes content wrapped in $$ as LaTeX
    .replace(/\\\((.*?)\\\)|\\\[(.*?)\\\]/gs, (_, p1, p2) => `$$${p1 || p2}$$`
      // Remove section links
      .replace(/\[(.*?)\]\(\/source\?ref=.*?\)/g, (_, p1) => p1))
    // Escape ampersands in text blocks within LaTeX without breaking ampersand usage in formulae
    .replace(/\{([^{}]*?)&([^{}]*?)\}/g, (_, p1, p2) => `{${p1}\\&${p2}}`);
};

const rehypePlugins = [rehypeKatex];
const remarkPlugins: PluggableList = [remarkGfm, [remarkMath, { singleDollarTextMath: false }]];

export function AnswerMarkdown({ answer, renderInlineCitation }: Props) {
  const components = useMemo(() => ({
    a: ({ ...props }) => <AnswerLink {...props} RenderInlineCitation={renderInlineCitation}/>,
    p: Paragraph,
    h1: (props: React.HTMLProps<HTMLHeadingElement>) => <Heading tag="h1" {...props} />,
    h2: (props: React.HTMLProps<HTMLHeadingElement>) => <Heading tag="h2" {...props} />,
    h3: (props: React.HTMLProps<HTMLHeadingElement>) => <Heading tag="h3" {...props} />,
    h4: (props: React.HTMLProps<HTMLHeadingElement>) => <Heading tag="h4" {...props} />,
    h5: (props: React.HTMLProps<HTMLHeadingElement>) => <Heading tag="h5" {...props} />,
    h6: (props: React.HTMLProps<HTMLHeadingElement>) => <Heading tag="h6" {...props} />,
    ul: UnorderedList,
    ol: OrderedList,
    li: ListItem,
    strong: Strong,
    em: Emphasis,
    table: Table,
    thead: TableHead,
    th: TableHeader,
    tbody: TableBody,
    tr: TableRow,
    td: TableDataCell
  }), [renderInlineCitation]);

  return (
    <ReactMarkdown
      className="[&_.katex-display_>.katex]:whitespace-normal"
      allowedElements={allowedElements}
      rehypePlugins={rehypePlugins}
      remarkPlugins={remarkPlugins}
      unwrapDisallowed={true}
      skipHtml={true}
      components={components}
    >
      { preprocessLatex(answer) }
    </ReactMarkdown>
  )
}

const Heading = ({ tag, children }: PropsWithChildren<{ tag: keyof JSX.IntrinsicElements; }>) => {
  const Tag = tag;
  const headingStyle = clsx('font-medium leading-[2.75rem]', {
    'text-[1.375rem]': tag === 'h1',
    'text-xl': tag === 'h2',
    'text-lg': tag === 'h3',
    'text-base': tag === 'h4' || tag === 'h5' || tag === 'h6'
  });
  return <Tag className={headingStyle}>{children}</Tag>;
};


function Strong({ children }: PropsWithChildren) {
  return (
    <strong className="font-semibold">
      { children }
    </strong>
  )
}

function Emphasis({ children }: PropsWithChildren) {
  return (
    <em>
      { children }
    </em>
  )
}

function Paragraph({ children }: PropsWithChildren) {
  return (
    <p className="mb-4">
      { children }
    </p>
  )
}

function UnorderedList({ children }: PropsWithChildren) {
  return (
    <ul className="ml-8 mb-4 list-disc list-outside [&_p]:mb-1">
      { children }
    </ul>
  )
}

function OrderedList({ children }: PropsWithChildren) {
  return (
    <ol className="ml-8 mb-4 list-decimal list-outside [&_p]:mb-1">
      { children }
    </ol>
  )
}

function ListItem({ children }: PropsWithChildren) {
  return (
    <li className="mb-1">
      { children }
    </li>
  )
}

function Table({ children }: PropsWithChildren) {
  return (
    <table className="min-w-full my-8 divide-y divide-gray-200">
      { children }
    </table>
  )
}
function TableHead({ children }: PropsWithChildren) {
  return (
    <thead className="text-left">
    {children}
    </thead>
  )
}

function TableBody({ children }: PropsWithChildren) {
  return (
    <tbody className="divide-y divide-gray-200">
      { children }
    </tbody>
  )
}

function TableHeader({ children }: PropsWithChildren) {
  return (
    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold">
      { children }
    </th>
  )
}

function TableDataCell({ children }: PropsWithChildren) {
  return (
    <td className="py-4 pl-4 pr-3 text-sm font-medium">
      {children}
    </td>
  )
}

function TableRow({ children }: PropsWithChildren) {
  return (
    <tr className="even:bg-blue-50">
      { children }
    </tr>
  )
}

export function AnswerLink({ RenderInlineCitation, ...props }: React.HTMLProps<HTMLAnchorElement> & {
  RenderInlineCitation?: InlineCitationRender;
}) {
  const { trackAnswerLinkClicked } = useAnalyticsEvent();
  const { threadId, chatId } = useGlobalAnalyticsContext();
  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    props.onClick && props.onClick(event);
    if (props.href) {
      const sourceRegex = /\/source\?ref=(\w+)\/.+/;
      const match = sourceRegex.exec(props.href);
      const [referred, sourceType] = match ?? [props.href, ''];
      trackAnswerLinkClicked({ referred, sourceType });
    }
  };

  if (props.href?.startsWith('/sources/')) {
    const index = props.href.split('/')[2];
    return RenderInlineCitation && (
      <RenderInlineCitation {...props} index={parseInt(index) - 1}/>
    )

  }

  const source = props.href
    ? getSourceTypeAndParams(props.href.split('=')[1] ?? '')
    : null;

  return (
    <>
      {threadId && chatId && source ? (
        <Link
          className="text-blue-400 hover:underline underline-offset-4 inline-block"
          onClick={handleLinkClick}
          to={`/${threadId}/chat/${chatId}/inline-source`}
          state={{ inlineSource: source, inlineHref: props.href }}
        >
          {props.children}
        </Link>
      ) : (
        <a
          target="_blank"
          rel="noreferrer noopener"
          className="text-blue-400 hover:underline underline-offset-4 inline-block"
          onClick={handleLinkClick}
          {...props}
        />
      )
      }
    </>
  )
}
