import { PlusIcon, XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { ArrowRightStartOnRectangleIcon, Cog6ToothIcon, QuestionMarkCircleIcon, UsersIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Button } from '../../../components/button';
import { useConfigContext } from '../../config/ConfigContext';
import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';
import { useAskBluejApiContext } from '../../core/api/ask-bluej-api';
import { useIsUserAdmin } from '../../core/user/context';
import { useMobileHeaderContext } from '../contexts/MobileHeaderContext';
import { ThreadList } from './thread-list-view/thread-list';
import { FaqInteractionContext } from "../types";
import AutosizingTextarea from 'react-textarea-autosize';
import { useShowThreadSearch } from '../../core/feature-flag/features/use-show-thread-search';

type Props = {
  threadId?: string;
  showCloseButton?: boolean;
}

let timer: NodeJS.Timeout

function debounce(func: (...args: string[]) => void, timeout: number): (...args: string[]) => void {
  return (...args: string[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), timeout);
  };
}

export function ThreadSidebar(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = useIsUserAdmin();
  const [searchTextValue, setSearchTextValue] = React.useState('');
  const [filterValue, setFilterValue] = React.useState('');
  const showThreadSearch = useShowThreadSearch();
  const listTop = useRef<HTMLDivElement>(null);

  const { apiUrl } = useConfigContext();
  const { api } = useAskBluejApiContext();
  const {
    trackLogoutClicked,
    trackLogoClicked,
    trackNewThreadClicked,
    trackFaqClicked,
    trackSettingsClicked,
    trackThreadSearched,
    trackSearchCleared
  } = useAnalyticsEvent();
  const { threadMenuVisible, toggleThreadMenuVisibility } = useMobileHeaderContext();

  const { threadId, showCloseButton } = props;
  const handleNewThread = async () => {
    trackNewThreadClicked();
    if (location.pathname !== '/') {
      navigate('/');
    }
    threadMenuVisible && toggleThreadMenuVisibility();
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchTextValue(event.target.value);
  }

  const handleClearFilter = () => {
    trackSearchCleared();
    setSearchTextValue('');
    setFilterValue('');
    listTop.current && listTop.current.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    const setFilter = () => {
      searchTextValue && trackThreadSearched({ searchText: searchTextValue });
      setFilterValue(searchTextValue);
    }
    debounce(setFilter, 500)();
  }, [searchTextValue]);

  return (
    <>
      { showCloseButton &&
        <div className="flex items-center h-20 md:h-24 ml-auto mr-4 md:mr-10">
          <Button
            colour="secondary"
            title="Close thread sidebar"
            onClick={toggleThreadMenuVisibility}
            variant="icon"
            className="text-blue-400 hover:text-blue-500"
          >
            <span className="sr-only">Close thread panel</span>
            <XMarkIcon className="h-8 w-8" aria-hidden="true"/>
          </Button>
        </div>
      }
      <div className="-mt-8 lg:mt-0 text-center lg:border-b border-b-grey-300 pb-2 lg:pt-2 w-full mx-auto">
        <Link to="/" className="inline-block align-middle" title="Return to home" onClick={() => {
          trackLogoClicked();
          threadMenuVisible && toggleThreadMenuVisibility();
        }}>
          <img className="w-48 h-auto"
               src="/logo.svg"
               alt="Blue J Logo" />
        </Link>
      </div>
      <div className="pt-3 px-3 md:px-10 lg:px-3 py-3">
        <Button colour="primary" title="Create New Thread" variant="contained" onClick={handleNewThread} className="w-full text-left py-2 px-2">
          <PlusIcon className="w-6 h-6 inline-block align-middle"/>
          New Thread
        </Button>
      </div>
      { showThreadSearch && <>
      <hr/>
      <div className="p-3 flex items-center">
        <div className="flex items-center border border-grey-400 rounded bg-white w-full">
          <MagnifyingGlassIcon className="w-5 h-5 mx-2" />
          <AutosizingTextarea
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            value={searchTextValue}
            onChange={handleFilterChange}
            placeholder="Search threads"
            className="h-10 pl-0 block w-full focus:ring-0 focus:outline-0 border-0 bg-transparent max-h-40 resize-none"
            data-1p-ignore
          />
        </div>
        {filterValue && <Button colour="primary" title="Create New Thread" variant="contained" onClick={handleClearFilter} className=" p-2 ml-2">
          Clear
        </Button>}
      </div>
      </> }
      <div className="overflow-hidden flex flex-1 flex-col">
        <div className="w-full mx-auto overflow-y-scroll flex-1">
          <div ref={listTop}></div>
          <ThreadList activeThreadId={threadId} filter={filterValue} />
        </div>
      </div>
      <div className="p-4 border-t border-top-grey-300">
        <div className="flex flex-row items-center justify-around lg:justify-between">
          <Link
            to="/settings"
            onClick={() => {
              toggleThreadMenuVisibility();
              trackSettingsClicked();
            }}
            title="Settings"
            className="flex items-center gap-1 bg-transparent text-xs text-grey-600 hover:border-blue-500 hover:text-blue-500 focus:outline-none focus-visible:ring-2"
          >
            <Cog6ToothIcon className="inline-block h-5 w-5 align-middle" aria-hidden={true} />
            Settings
          </Link>
          <Link
            to="https://support.bluej.com/hc/en-us"
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => trackFaqClicked(FaqInteractionContext.MENU_BAR)}
            title="Help Center"
            className="flex items-center gap-1 bg-transparent text-xs text-grey-600 hover:border-blue-500 hover:text-blue-500 focus:outline-none focus-visible:ring-2"
          >
            <QuestionMarkCircleIcon className="inline-block h-5 w-5 align-middle" aria-hidden={true} />
            Help Center
          </Link>
          <a
            href={api.generateAuthLink(apiUrl, `${window.location.origin}/login`, 'logout')}
            onClick={trackLogoutClicked}
            title="Logout"
            className="flex items-center gap-1 bg-transparent text-xs text-grey-600 hover:border-blue-500 hover:text-blue-500 focus:outline-none focus-visible:ring-2"
          >
            <ArrowRightStartOnRectangleIcon className="inline-block h-5 w-5 align-middle" aria-hidden={true} />
            Logout
          </a>
        </div>
        <div className="mt-4">
          <p className="text-grey-500 text-xs">The information provided does not, and is not intended to, constitute
            legal advice. Generative AI systems can make mistakes. Verify all important information. </p>
        </div>
        {isAdmin && (
          <Link
            to="/admin"
            title="Admin"
            className="mt-4 flex max-w-fit items-center mx-auto lg:mx-0 gap-1 bg-transparent text-xs text-grey-600 hover:border-blue-500 hover:text-blue-500 focus:outline-none focus-visible:ring-2"
          >
            <UsersIcon className="inline-block h-5 w-5 align-middle" aria-hidden={true} />
            Admin
          </Link>
        )}
      </div>
    </>
  )
}
