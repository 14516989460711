import React from 'react';

export type Criteria = {
  title: string;
  text: React.ReactElement;
};

export function getCriteriaBasedCautionaryText(criteria: string | null, onClick: () => void): Criteria | null {
  switch (criteria) {
    case 'state':
      return {
        title: 'Asking about state and local tax?',
        text: <>
          Currently, Blue J relies on a comprehensive US Federal Tax database, and is unlikely to answer SALT questions well. Consult our <a className="underline" target="_blank" href='https://support.bluej.com/hc/en-us/articles/30133433035540-What-data-is-Blue-J-trained-on' onClick={onClick}>Help Center</a> for detail on Blue J’s current coverage and stay tuned in the coming months for improved SALT capability.
        </>
      };
    case 'province':
      return {
        title: 'Asking about provincial tax?',
        text: <>
          Currently, Blue J is not trained on provincial tax law and is unlikely to answer provincial questions well. Consult our <a className="underline" target="_blank" href='https://support.bluej.com/hc/en-us/articles/30133433035540-What-data-is-Blue-J-trained-on' onClick={onClick}>Help Center</a> for more detail on Blue J’s coverage.
        </>
      };
    case 'question':
      return {
        title: 'Asking a complex question?',
        text: <>It looks like you might be asking a complex, compound question. If the answer is unsatisfactory, try breaking the question down into simpler parts and asking them one at a time.</>
      };
    case 'case':
      return {
        title: 'Asking about cases?',
        text: <>Blue J's algorithm is trained on UK tax content and does not yet include case law. Consult our <a className="underline" target="_blank" href='https://support.bluej.com/hc/en-us/articles/31706625979796-What-data-is-Blue-J-UK-trained-on' onClick={onClick}>Help Center</a> for more detail on Blue J's coverage.</>
      };
    case 'eta':
      return {
        title: 'Asking about the Excise Tax Act?',
        text: <>Currently, Blue J’s algorithm is trained on the ITA and is unlikely to answer ETA questions well. Consult our <a className="underline" target="_blank" href='https://support.bluej.com/hc/en-us/articles/30133433035540-What-data-is-Blue-J-trained-on' onClick={onClick}>Help Center</a> for more detail on Blue J’s coverage.</>
      };
    case 'pass':
    case 'unknown':
    default:
      return null;
  }
}
